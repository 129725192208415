import { RecoilRoot } from "recoil";
import { createRoot } from "react-dom/client";
import "./index.css";
import { Playground } from "./components/playground";
import { isStaging, massApiUrl } from "./utils/utils";
import { ToastContainer } from "react-toastify";
import background from "./assets/Background.png";

import "react-toastify/dist/ReactToastify.css";

if (isStaging) {
  console.log("Staging mode");
  (window as any).useMassSdkStatingContracts = true;
  (window as any).useMassSdkUrl = massApiUrl;
}

declare var ethereum: any;

function App() {
  return (
    <div className="relative w-screen h-screen bg-background z-10">
      <img
        src={background}
        className="absolute w-full h-full top-0 left-0 z-10 pointer-events-none"
      />
      <div className="h-full w-full text-font pt-2.5 px-2.5 relative z-50">
        <Playground />
        <ToastContainer position="bottom-center" theme="dark" newestOnTop />
      </div>
    </div>
  );
}

const root = createRoot(document.getElementById("root")!);
root.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>
);
