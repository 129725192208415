import { Header } from "./Header";
import { Screens } from "./Screens";
import { useTab, useWallet } from "../utils/utils";

declare var ethereum: any;

export function Playground() {
  const [currentTab, setCurrentTab] = useTab();

  const [wallet] = useWallet();

  // const execs = [];
  // const alen = status.type === "compiled" && status.approvesRequired.length;
  // if (alen) {
  //   execs.push(alen === 1 ? "1 approval" : `${alen} approvals`);
  // }
  // if (bytecode?.tx) {
  //   execs.push("vault transaction");
  // }
  // const siglen =
  //   (bytecode?.txGasless ? 1 : 0) +
  //   (bytecode?.txGasless712 ? 2 : 0) +
  //   (bytecode?.txGaslessBundled ? 1 : 0) +
  //   (bytecode?.requiredSignatures.length ?? 0);
  // if (siglen) {
  //   execs.push(siglen === 1 ? "1 signature" : `${siglen} signatures`);
  // }

  // const executeWhat = execs.join(" + ");

  return wallet ? (
    <div className="w-full h-full flex flex-col gap-2 flex-1 self-stretch items-start rounded-t-[34px] border-2 border-border pt-2 px-2 bg-surface-gradient bg-surface">
      <div className="flex flex-col items-start flex-1 self-stretch pt-1 px-0.5 h-full w-full">
        <div className="w-full h-full flex flex-col justify-center items-start flex-1 self-stretch rounded-t-3xl bg-background border-t-4 border-x-2 border-border">
          <Header currentTab={currentTab} onTabChange={setCurrentTab} />
          <Screens currentTab={currentTab} />
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
}
