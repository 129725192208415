import type { HexString } from "@mass-money/sdk/web";
import { useCallback, useEffect, useState } from "react";
import { useEventListener, useIsomorphicLayoutEffect } from "usehooks-ts";
import { chainIds, Net } from "../constants/config";
import { BigNumber, Contract, providers, utils } from "ethers";
import { GelatoRelay, TaskState } from "@gelatonetwork/relay-sdk";
import {
  PublicClient,
  WalletClient,
  createPublicClient,
  createWalletClient,
  custom,
  http,
} from "viem";
import { useRecoilState } from "recoil";
import { tabAtom } from "../atoms/atom";

export const isStaging = process.env.MASS_ENV === "staging";

export const massApiUrl =
  process.env.MASS_API_URL ||
  (isStaging ? "https://api.staging.mass.money" : "https://api.mass.money");

export function useHash() {
  const [hash, setHash] = useState(() => window.location.hash);

  const hashChangeHandler = useCallback(() => {
    setHash(window.location.hash);
  }, []);

  useEffect(() => {
    window.addEventListener("hashchange", hashChangeHandler);
    return () => {
      window.removeEventListener("hashchange", hashChangeHandler);
    };
  }, []);

  const updateHash = useCallback(
    (newHash: any) => {
      if (newHash !== hash) window.location.hash = newHash;
    },
    [hash]
  );

  return [hash, updateHash] as const;
}

export interface Size {
  width: number;
  height: number;
}

export function useElementSize<T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  Size
] {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  // Prevent too many rendering using useCallback
  const handleSize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  useEventListener("resize", handleSize);

  useIsomorphicLayoutEffect(() => {
    handleSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  return [setRef, size];
}

declare var ethereum: any;

type Wallet = {
  client: WalletClient;
  net: Net;
  accounts: HexString[];
  connectedAccount: HexString | null;
};

export function useWallet(): [Wallet | null, (accounts: HexString[]) => void] {
  const [result, setResult] = useState<Wallet | null>(null);

  useEffect(() => {
    const loadWallet = async () => {
      try {
        if (typeof ethereum === "undefined") {
          throw new Error("Metamask not detected");
        }
        const client = createWalletClient({
          transport: custom((window as any).ethereum),
        });
        const accounts = (await client.getAddresses()).map(
          (account) => account.toLowerCase() as HexString
        );
        const chainId = await client.getChainId();
        const chainHex = `0x${chainId.toString(16)}`;
        const net = chainIds[chainHex];
        if (!net) {
          throw new Error("Unsupported chain " + chainHex);
        }
        const result = { client, net, accounts, connectedAccount: accounts[0] };
        setResult(result);
      } catch (e) {
        console.error("Failed to detect network", e);
      }
    };
    loadWallet();
  }, [ethereum]);

  const setAccounts = (accounts: HexString[]) => {
    if (!result) {
      return;
    }
    const newResult: Wallet = {
      ...result,
      accounts,
      connectedAccount: accounts[0],
    };
    setResult(newResult);
  };

  return [result, setAccounts];
}

export function usePublicClient(): PublicClient | null {
  const [wallet] = useWallet();

  const [publicClient, setPublicClient] = useState<PublicClient | null>(null);

  useEffect(() => {
    if (!wallet) {
      return;
    }
    setPublicClient(
      createPublicClient({
        chain: wallet.net.chain,
        transport: custom(ethereum),
      })
    );
  }, [wallet]);

  return publicClient;
}

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (deepEqual(value, debouncedValue)) {
        return;
      }
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  });

  return debouncedValue;
}

export function deepEqual<T>(obj1: T, obj2: T) {
  if (obj1 === obj2) {
    return true;
  } else if (isObject(obj1) && isObject(obj2)) {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    for (const prop in obj1) {
      if (!deepEqual(obj1[prop], obj2[prop])) {
        return false;
      }
    }
    return true;
  }
  // Private
  function isObject(obj: any): obj is object {
    if (typeof obj === "object" && obj != null) {
      return true;
    } else {
      return false;
    }
  }
}

export function by<TKey, TOrig, TTarget>(
  col: TOrig[],
  key: (val: TOrig) => TKey,
  value: (val: TOrig) => TTarget
): Map<TKey, TTarget>;
export function by<TKey, T>(
  col: T[],
  key: (val: T) => TKey,
  overrideDuplicates?: boolean
): Map<TKey, T>;
export function by(
  col: any[],
  key: (val: any) => string,
  valOrKey?: boolean | ((val: any) => any)
): Map<any, any> {
  const ret = new Map();
  const val =
    typeof valOrKey === "boolean" || !valOrKey ? (x: any) => x : valOrKey;
  const ovr = valOrKey === true;
  for (const v of col) {
    const k = key(v);
    if (!ovr && ret.has(k)) {
      throw new Error(`Duplicate key "${k}"`);
    }
    ret.set(k, val(v));
  }
  return ret;
}

export function toLookup<T, TKey>(
  array: readonly T[],
  keySelector: (item: T, idx: number) => TKey
): Map<TKey, T[]> {
  const map = new Map<TKey, T[]>();
  let i = 0;
  for (const a of array) {
    const key = keySelector(a, i++);
    const arr = map.get(key);
    if (arr) {
      arr.push(a);
    } else {
      map.set(key, [a]);
    }
  }
  return map;
}

export function toNumber(num: BigNumber, decimals: number): number {
  const str = utils.formatUnits(num, decimals);
  return parseFloat(str);
}

export type nil = null | undefined;

export function nullish(val: any): val is nil {
  return val === null || val === undefined;
}

export function notNil<T>(value: (T | nil)[]): Exclude<T, null>[] {
  return value.filter((x) => !nullish(x)) as any[];
}

const provider = new providers.Web3Provider(ethereum, "any");
const ERC20minimal = new utils.Interface([
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", type: "uint8" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      { name: "", type: "address" },
      { name: "", type: "address" },
    ],
    name: "allowance",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
]);

export async function isAllowed(
  tokenAddress: HexString,
  owner: HexString,
  spender: HexString,
  amt: bigint | nil
): Promise<boolean> {
  const token = new Contract(tokenAddress, ERC20minimal, provider);
  const allowance: BigNumber = await token.allowance(owner, spender);
  let amtBig = amt && BigNumber.from(amt);
  if (!amtBig) {
    const digits = 10 + (await token.decimals());
    amtBig = BigNumber.from(10).pow(digits);
  }
  return allowance.gte(amtBig);
}

export function delay(ms: number) {
  return new Promise<void>((res) => setTimeout(() => res(), ms));
}

export async function waitForGelatoTxId(
  taskId: HexString,
  shouldWaitStateConfirmed: boolean = false
): Promise<HexString> {
  const relay = new GelatoRelay();

  let retry = 0;

  while (++retry < 10) {
    const task = await relay.getTaskStatus(taskId);

    if (task) {
      if (task.taskState === TaskState.Cancelled) {
        throw new Error("task cancelled");
      }

      const isInState = shouldWaitStateConfirmed
        ? [TaskState.ExecSuccess, TaskState.ExecReverted].includes(
            task.taskState
          )
        : true;

      if (task.transactionHash && isInState) {
        return task.transactionHash as HexString;
      }
    }

    await delay(3000);
  }

  throw new Error("cannot get transaction hash");
}

export async function waitForSchedulerTask(
  taskId: HexString,
  shouldWaitStateConfirmed: boolean = false
): Promise<{ txHash: HexString; calldata: HexString } | nil> {
  let retry = 0;

  while (++retry < 20) {
    const res = await fetch(
      `${massApiUrl}/compiler/scheduler-task?id=${taskId}`
    );
    const task = await res.json();

    const isInState = shouldWaitStateConfirmed
      ? ["succeeded", "failed"].includes(task.status)
      : true;

    if (task.txHash && isInState) {
      return { txHash: task.txHash, calldata: task.calldata };
    }

    await delay(100);
  }

  return null;
}

export async function waitForConfirmedTx(
  txId: HexString,
  net: Net
): Promise<HexString> {
  const client = createPublicClient({
    transport: http(net.rpc),
  });

  let retry = 0;

  while (++retry < 10) {
    const receipt = await client.waitForTransactionReceipt({
      hash: txId,
      pollingInterval: 5000,
    });
    if (receipt) {
      return receipt.transactionHash;
    }
  }

  throw new Error("transaction is not yet confirmed");
}

export function shortenAddress(zx?: HexString | null, veryShort?: boolean) {
  if (!zx) {
    return "-";
  }

  return `${zx.substr(0, veryShort ? 3 : 5)}…${zx.substr(
    zx.length - (veryShort ? 3 : 4)
  )}`;
}

export function useClickOutside(
  ref: React.RefObject<HTMLDivElement>,
  onClickOutside: VoidFunction
) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export function formatQuantity(quantity: number) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 6,
  }).format(quantity);
}

export function useTab() {
  const [currentTab, setCurrentTab] = useRecoilState(tabAtom);

  return [currentTab, setCurrentTab] as const;
}

// TODO: improve error handling
export async function parseExplorerLink(link: string) {
  const chain = Object.values(chainIds).find((chain) =>
    link.startsWith(chain.explorer)
  );

  if (!chain?.apiUrl || !chain?.apiKey) {
    return link;
  }

  const hash = link.split("tx/")[1];
  if (!hash) {
    return link;
  }

  try {
    const response = await fetch(
      `${chain.apiUrl}?module=proxy&action=eth_getTransactionByHash&txhash=${hash}&apikey=${chain.apiKey}`
    );
    const data = await response.json();
    return data?.result?.input || link;
  } catch {
    return link; // Return the original link in case of an error
  }
}
