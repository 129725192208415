import { computeExpectedVaultAddress, HexString } from "@mass-money/sdk/web";
import { Button } from "../ui-kit/Button";
import { useHash, useWallet } from "../utils/utils";
import { keccak256 } from "viem";

export function Connect({ isSmall }: { isSmall?: boolean }) {
  const [wallet, setAccounts] = useWallet();
  const [, setHash] = useHash();

  const handleConnect = async () => {
    if (!wallet) {
      return;
    }
    try {
      const accounts = (await wallet.client.requestAddresses()).map(
        (account) => account.toLowerCase() as HexString
      );
      if (!accounts.length) {
        return;
      }
      const walletAddress = accounts[0];
      const vaultAddress = await computeExpectedVaultAddress({
        rpcUrl: wallet.net.rpc,
        creatorAddress: walletAddress,
        salt: keccak256(walletAddress),
      });
      setHash(vaultAddress);
      setAccounts(accounts);
    } catch (error) {
      console.error("Failed to connect", error);
    }
  };

  return (
    <Button
      size={isSmall ? "small" : "default"}
      onClick={handleConnect}
      isDisabled={!wallet}
    >
      Connect
    </Button>
  );
}
