import { useRef, useState } from "react";
import { useClickOutside, useWallet } from "../utils/utils";
import { Chips } from "../ui-kit/Chips";
import { Popover } from "../ui-kit/Popover";
import { chainIds } from "../constants/config";
import clsx from "clsx";
import { Caret } from "../assets/Caret";
import { Check } from "../assets/Check";

declare var ethereum: any;

export function NetworkMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, () => setIsOpen(false));
  const [wallet] = useWallet();
  const switchNetwork = async (chainId: string) => {
    if (!wallet) {
      return;
    }
    try {
      await wallet.client.switchChain({ id: parseInt(chainId) });
      // temp fix: reload page
      window.location.reload();
      setIsOpen(false);
    } catch (error) {
      console.error("Failed to switch network:", error);
    }
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {wallet && (
        <Chips
          isActive={isOpen}
          onClick={() => setIsOpen(true)}
          label={wallet.net.name}
          leading={<img src={wallet.net.icon} className="w-6 h-6" />}
          icon={
            <Caret
              className={clsx(
                "transition-all ease-out duration-[160ms] w-4 h-4",
                isOpen ? "rotate-180" : ""
              )}
            />
          }
        />
      )}
      {isOpen && (
        <Popover className="absolute !p-0 w-[320px] !gap-0">
          <div className="p-3 flex justify-center items-center">
            Select network
          </div>
          <>
            {Object.entries(chainIds).map(([key, { apiId, name, icon }]) => (
              <div
                key={apiId}
                onClick={() => switchNetwork(key)}
                role="menuitem"
                className="flex p-3 gap-2 items-center self-stretch cursor-pointer"
              >
                <img src={icon} className="w-6 h-6" />
                <span className="flex-1">{name}</span>
                {wallet?.net?.apiId === apiId && <Check />}
              </div>
            ))}
          </>
        </Popover>
      )}
    </div>
  );
}
