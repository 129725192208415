import { useState, useRef, useEffect } from "react";
import { Button } from "../ui-kit/Button";
import clsx from "clsx";
import { Caret } from "../assets/Caret";
import { Popover } from "../ui-kit/Popover";
import {
  formatQuantity,
  massApiUrl,
  useClickOutside,
  useHash,
  usePublicClient,
  useWallet,
} from "../utils/utils";
import { Input } from "../ui-kit/Input";
import { Switch } from "../ui-kit/Switch";
import { useRecoilState } from "recoil";
import { gasTankAtom } from "../atoms/atom";
import { Chips } from "../ui-kit/Chips";
import { callVault, HexString, VaultConfig } from "@mass-money/sdk/web";
import dedent from "dedent";
import { toast } from "react-toastify";

export function GasTankMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [msaUsdcBalance, setMsaUsdcBalance] = useState<number>();
  const [usdcAmountToAdd, setUsdcAmountToAdd] = useState<number>();
  const [balance, setBalance] = useState({
    current: 0,
    spent: 0,
  });
  const [isGasTankEnabled, setIsGasTankEnabled] = useRecoilState(gasTankAtom);

  const [wallet] = useWallet();
  const publicClient = usePublicClient();
  const [hash] = useHash();
  const vaultAddress = hash.replace("#", "") as HexString;

  useEffect(() => {
    if (!wallet || !hash || !publicClient) {
      return;
    }

    const fetchGasTankerBalance = async () => {
      if (!wallet.connectedAccount) {
        return;
      }
      try {
        const response = await fetch(
          `${massApiUrl}/compiler/gas-tanker/user-balance?userId=${wallet.connectedAccount}`
        ).then((res) => res.json());
        setBalance({
          current: response.balance / 10 ** 6,
          spent: response.spent / 10 ** 6,
        });
      } catch (error) {
        console.error(error);
      }
    };

    const fetchMsaUsdcBalance = async () => {
      try {
        const usdcBalance = await publicClient.readContract({
          address: wallet.net.usdc,
          abi: [
            {
              inputs: [
                {
                  internalType: "address",
                  name: "account",
                  type: "address",
                },
              ],
              name: "balanceOf",
              outputs: [
                {
                  internalType: "uint256",
                  name: "",
                  type: "uint256",
                },
              ],
              stateMutability: "view",
              type: "function",
            },
          ],
          functionName: "balanceOf",
          args: [vaultAddress],
        });
        setMsaUsdcBalance(Number(usdcBalance) / 10 ** 6);
      } catch (error) {
        console.error(error);
      }
    };

    const interval = setInterval(() => {
      fetchGasTankerBalance();
      fetchMsaUsdcBalance();
    }, 5000);

    return () => clearInterval(interval);
  }, [wallet, publicClient]);

  useClickOutside(dropdownRef, () => setIsOpen(false));

  const handleAddGas = async () => {
    await toast.promise(
      async () => {
        if (wallet && wallet.connectedAccount) {
          const config: VaultConfig = {
            vaultAddress: vaultAddress,
            rpcUrl: wallet.net.rpc,
            excludedDexes: ["Portals"],
          };
          const code = dedent`
          #use gasTank;

          activate();
          deposit(${usdcAmountToAdd} usdc);
        `;

          const result = await callVault(config, code);

          await wallet.client.sendTransaction({
            account: wallet.connectedAccount,
            chain: wallet.net.chain,
            to: result.tx?.to,
            value: result.tx?.value,
            data: result.tx?.data,
          });

          setIsOpen(false);
        }
      },
      {
        pending: "Adding gas...",
        error: "Error while adding gas",
        success: "Gas successfully added",
      }
    );
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {!isGasTankEnabled ? (
        <Button
          size="small"
          onClick={() => setIsOpen(true)}
          isActive={isOpen}
          icon={
            <Caret
              color="on-accent"
              className={clsx(
                "transition-all ease-out duration-[160ms] w-4 h-4",
                isOpen ? "rotate-180" : ""
              )}
            />
          }
        >
          Enable gas tank
        </Button>
      ) : (
        <Chips
          isActive={isOpen}
          onClick={() => setIsOpen(true)}
          label={`${balance.current.toFixed(2)} USDC`}
          icon={
            <Caret
              className={clsx(
                "transition-all ease-out duration-[160ms] w-4 h-4",
                isOpen ? "rotate-180" : ""
              )}
            />
          }
        />
      )}
      {isOpen && (
        <Popover className="absolute w-[320px] right-0">
          <div className="flex flex-col gap-3 self-stretch items-start font-medium">
            <div className="flex justify-center items-center gap-2 self-stretch w-full">
              <span className="font-youth flex-1">Use gas tank</span>
              <Switch
                isActive={isGasTankEnabled}
                onChange={() => setIsGasTankEnabled((prev) => !prev)}
              />
            </div>
            {isGasTankEnabled && balance.current > 0 ? (
              <>
                <div className="h-0.5 w-full rounded-[1px] bg-background self-stretch" />
                <div className="flex items-baseline gap-2 self-stretch font-medium w-full">
                  <span className="text-font-variant flex-1">Gas credit</span>
                  <span className="font-youth flex-1 text-right">{`${balance.current} USDC`}</span>
                </div>
                <div className="flex items-baseline gap-2 self-stretch font-medium w-full">
                  <span className="text-font-variant flex-1">
                    Total fees paid
                  </span>
                  <span className="font-youth flex-1 text-right">{`${balance.spent} USDC`}</span>
                </div>
              </>
            ) : (
              <div className="text-font-variant text-sm">
                By using the gas tank, you get :
                <li className="flex items-baseline gap-2">
                  <span>•</span>
                  <span>A way to pay gas fees on all supported networks</span>
                </li>
                <li className="flex items-baseline gap-2">
                  <span>•</span>
                  <span>Access to all of the SDK functions and features</span>
                </li>
              </div>
            )}
          </div>
          <div className="h-0.5 w-full rounded-[1px] bg-background self-stretch" />
          <div className="flex flex-col gap-3 items-start self-stretch">
            <div className="flex flex-col gap-1 items-start self-stretch">
              <Input
                isDisabled={!isGasTankEnabled}
                type="number"
                onChange={(value) => setUsdcAmountToAdd(Number(value))}
                value={usdcAmountToAdd}
                placeholder="0.00"
                trailing={
                  <span
                    data-disabled={!isGasTankEnabled}
                    className="text-sm font-youth data-[disabled=true]:text-font-disabled"
                  >
                    USDC
                  </span>
                }
              />
              <span className="text-xs font-medium flex-1 text-right text-font-variant w-full">{`${formatQuantity(
                msaUsdcBalance ?? 0
              )} available`}</span>
            </div>
            {isGasTankEnabled ? (
              <Button
                isFullWidth
                isDisabled={!msaUsdcBalance || !usdcAmountToAdd}
                onClick={handleAddGas}
              >
                Add gas
              </Button>
            ) : (
              <Button isFullWidth onClick={() => setIsGasTankEnabled(true)}>
                Enable gas tank
              </Button>
            )}
          </div>
        </Popover>
      )}
    </div>
  );
}
