import {
  isAllowed,
  useHash,
  useWallet,
  waitForGelatoTxId,
  waitForSchedulerTask,
} from "../utils/utils";
import { Button } from "../ui-kit/Button";
import { Deco } from "./Deco";
import { CompilationResult, Editor } from "./editor";
import { Feedback, FeedbackProps } from "../ui-kit/Feedback";

import {
  DataToSign,
  fetchVaultOwner,
  Gasless712Tx,
  GaslessBundledTxs,
  GaslessTx,
  HexString,
  RequiredSignature,
  Tx,
} from "@mass-money/sdk/web";
import { useEffect, useState } from "react";
import { Simulator } from "./Simulator";
import { Modal } from "../ui-kit/Modal";
import { Main } from "../ui-kit/Main";
import { MainContent } from "../ui-kit/MainContent";
import { MainScreen } from "../ui-kit/MainScreen";
import dedent from "dedent";
import { Connect } from "./Connect";
import { useLocalStorage } from "usehooks-ts";
import { Tooltip } from "../ui-kit/Tooltip";

declare var ethereum: any;

type ExecutionResult = {
  calldata: HexString;
  txHash: HexString;
};

const DEFAULT_CODE = dedent`
#use vault;

// deposit some usdc from wallet
deposit(0.01 usdc);
usdc =  balance($USDC);
log("My USDC balance is: ", usdc);
price = 0.95 [usdt/usdc]; // we expect at least 1usdc = 0.95 usdt... but allow 5% slippage
gotUsdt = dex.uniswapV2(usdc, usdc * price);
log("... we got usdt: ", gotUsdt);
withdraw(gotUsdt);

// If your input is constant, you can also perform swaps via 0x or paraswap...
//   1) you might get better prices
//   2) you will avoid non existing liquidity pools
//   3) the interface is more user-friendly, since you dont have to specify an expected output amount:
// ex:
//
// dex.zerox(1 usdc, $DAI, 3%);
`;

export function Code() {
  const [hash] = useHash();
  const vaultAddress = hash.replace("#", "") as HexString;

  const [code, setCode] = useLocalStorage(
    "code",
    localStorage.getItem("code") ?? DEFAULT_CODE
  );

  const [refectchVaultOwnerTrigger, setRefectchVaultOwnerTrigger] = useState(0);
  const [wallet] = useWallet();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vaultOwner, setVaultOwner] = useState<HexString | "error" | null>(
    null
  );
  const [compilationResult, setCompilationResult] = useState<CompilationResult>(
    { type: "compiling" }
  );

  const [action, setAction] = useState<
    "compilation" | "simulation" | "execution"
  >("compilation");

  const isOwner =
    !!vaultOwner && !!wallet && vaultOwner === wallet.connectedAccount;
  const isVaultOwnerInAccounts =
    vaultOwner !== "error" && vaultOwner
      ? wallet?.accounts.includes(vaultOwner)
      : undefined;
  const isExecuteDisabled =
    !code ||
    !isOwner ||
    !wallet ||
    !wallet.connectedAccount ||
    compilationResult.type !== "compiled";
  const hasNoPrimaryTask =
    compilationResult.type === "compiled" && !compilationResult.result.tx;
  const hasScheduledTask =
    compilationResult.type === "compiled" &&
    !!compilationResult.result.txGaslessBundled;
  const isSimulateDisabled =
    compilationResult.type !== "compiled" ||
    hasNoPrimaryTask ||
    hasScheduledTask ||
    !code ||
    !wallet ||
    !vaultOwner ||
    vaultOwner === "error";

  const simulationWarning = hasNoPrimaryTask
    ? "Should have primary task"
    : hasScheduledTask
    ? "Cannot simulate scheduled statements"
    : undefined;

  const [executionFeedback, setExecutionFeedback] = useState<FeedbackProps>({
    status: "accent",
    title: "Executing...",
    description: undefined,
  });

  const [executionResults, setExecutionResults] = useState<
    ExecutionResult[] | null
  >(null);

  const [compilationFeedback, setCompilationFeedback] = useState<{
    label: string;
    status: string;
    description?: string;
  }>({
    label: "",
    status: "neutral",
  });

  const isConnected = wallet && wallet.connectedAccount && vaultAddress;

  useEffect(() => {
    if (!hash) {
      setCompilationFeedback({
        label: "",
        status: "neutral",
      });
      return;
    }

    if (!code) {
      setCompilationFeedback({
        label: "Compilation failed",
        status: "error",
        description: "Write some code to start compilation",
      });
      return;
    }

    setAction("compilation");

    if (compilationResult.type === "compiling") {
      setCompilationFeedback({
        label: "Compiling...",
        status: "accent",
      });
    }

    if (compilationResult.type === "compiled") {
      setCompilationFeedback({
        label: "Compilation successful",
        status: "success",
        // TODO: maybe dynamic
        description: isOwner
          ? "Ready to execute 1 calldata"
          : "You can only simulate since you are not the owner of this vault.",
      });
    }

    if (compilationResult.type === "compilation failed") {
      setCompilationFeedback({
        label: "Compilation failed",
        status: "error",
        description: compilationResult.error,
      });
    }
  }, [hash, compilationResult, code, wallet]);

  useEffect(() => {
    if (hash && action !== "compilation") {
      setCompilationResult({ type: "compiling" });
    }
  }, [hash, code]);

  useEffect(() => {
    (async () => {
      if (!wallet || !vaultAddress) {
        return;
      }

      try {
        const owner = await fetchVaultOwner(wallet.net.rpc, vaultAddress);
        setVaultOwner(owner);
      } catch (e) {
        console.error("Failed to detect vault owner", e);
        setVaultOwner("error");
        setTimeout(
          () => setRefectchVaultOwnerTrigger(refectchVaultOwnerTrigger + 1),
          1000
        );
      }
    })();
  }, [vaultAddress, wallet, refectchVaultOwnerTrigger]);

  const updatePendingExecution = (newDescription: string) => {
    setExecutionFeedback({
      status: "accent",
      title: "Executing...",
      description: newDescription,
    });
    setExecutionResults(null);
  };

  const updateFailedExecution = (newDescription: string) => {
    setExecutionFeedback({
      title: "Execution failed",
      status: "error",
      description: newDescription,
    });
    setExecutionResults(null);
  };

  const updateSuccessfullExecution = (results: ExecutionResult[]) => {
    setExecutionFeedback({
      status: "success",
      title: "Execution successful",
      description: `Executed ${results.length} calldata`,
    });
    setExecutionResults(results);
  };

  const handleExecuteClick = async () => {
    if (compilationResult.type !== "compiled" || !wallet) {
      return;
    }
    setAction("execution");

    const bytecode = compilationResult.result;

    // for (const bytecode.)
    const execute = async (tx: Tx) => {
      if (!wallet.connectedAccount) {
        return;
      }
      updatePendingExecution("Signing transaction...");
      try {
        const txHash = await wallet.client.sendTransaction({
          account: wallet.connectedAccount,
          chain: wallet.net.chain,
          to: tx.to,
          value: tx.value,
          data: tx.data,
        });
        updateSuccessfullExecution([{ calldata: tx.data, txHash }]);
      } catch (error) {
        updateFailedExecution("User rejected the request");
      }
    };

    const signTypedData = async (dataToSign: DataToSign) => {
      if (!wallet.connectedAccount) {
        return;
      }
      updatePendingExecution("Signing transaction...");
      try {
        const signature = await wallet.client.signTypedData({
          account: wallet.connectedAccount,
          types: dataToSign.types,
          domain: dataToSign.domain,
          message: dataToSign.message,
          primaryType: dataToSign.primaryType,
        });
        return signature;
      } catch (error) {
        updateFailedExecution("User rejected the request");
      }
    };

    const executeSignature = async (s: RequiredSignature) => {
      const signature = await signTypedData(s.dataToSign);
      updatePendingExecution("Sending signed message...");
      try {
        if (signature) {
          await s.execute(signature);
          updateSuccessfullExecution([]);
        }
      } catch (error) {
        updateFailedExecution("User rejected the request");
      }
    };

    const executeGasless = async (tx: GaslessTx) => {
      const signature = await signTypedData(tx.dataToSign);
      updatePendingExecution("Sending gasless transaction...");
      try {
        if (signature) {
          const txHash = await tx
            .execute(signature)
            .then((taskId) => waitForGelatoTxId(taskId));
          updateSuccessfullExecution([{ calldata: tx.bytecode, txHash }]);
        }
      } catch (error) {
        updateFailedExecution(
          `Error when sending gasless transaction: ${(error as any).message}`
        );
      }
    };

    const executeGasless712 = async (tx: Gasless712Tx) => {
      const signature = await signTypedData(tx.dataToSign);
      if (signature) {
        const gaslessTx = await tx.buildGaslessTx(signature);
        await executeGasless(gaslessTx);
      }
    };

    const executeGaslessBundledTxs = async (tx: GaslessBundledTxs) => {
      const signature = await signTypedData(tx.dataToSign);
      updatePendingExecution("Scheduling transactions...");

      try {
        if (signature) {
          const taskIds = (await tx.schedule(signature)) as HexString[];
          const executionResults: ExecutionResult[] = [];
          await Promise.all(
            taskIds.map(async (taskId) => {
              const taskInfo = await waitForSchedulerTask(taskId);
              if (taskInfo) {
                executionResults.push({
                  calldata: taskInfo.calldata,
                  txHash: taskInfo.txHash,
                });
              }
            })
          );
          updateSuccessfullExecution(executionResults);
        }
      } catch (error) {
        updateFailedExecution("Error when scheduling signed transactions");
      }
    };

    // execute required approves
    for (const a of bytecode.requiredApproves) {
      if (
        wallet.connectedAccount &&
        !(await isAllowed(
          a.token,
          wallet.connectedAccount,
          bytecode.vaultAddress,
          a.knownAmount?.amount
        ))
      ) {
        await execute(a.knownAmount?.performApprove ?? a.performApproveMax);
      }
    }

    // execute tx
    if (bytecode.tx) {
      await execute(bytecode.tx);
    }

    // execute tx gasless
    if (bytecode.txGasless) {
      await executeGasless(bytecode.txGasless);
    }

    // execute tx gasless712
    if (bytecode.txGasless712) {
      await executeGasless712(bytecode.txGasless712);
    }

    // execute tx gasless bundled
    if (bytecode.txGaslessBundled) {
      await executeGaslessBundledTxs(bytecode.txGaslessBundled);
    }

    // execute required signatures
    for (const s of bytecode.requiredSignatures) {
      await executeSignature(s);
    }
  };

  const handleAccountSwitch = async () => {
    // TODO:
  };

  return (
    <Main>
      <MainContent>
        <MainScreen>
          {wallet ? (
            <Editor
              code={code}
              onStatusChange={setCompilationResult}
              codeChange={setCode}
              myAddress={wallet.connectedAccount}
              error={
                compilationResult.type === "compilation failed"
                  ? compilationResult
                  : null
              }
              vaultAddress={vaultAddress}
              net={wallet.net}
            />
          ) : (
            <div />
          )}
        </MainScreen>
        {!isConnected && (
          <Feedback
            status="success"
            title="Compilation successful"
            description="Connect wallet to continue"
          />
        )}
        {isConnected && action === "compilation" && (
          <Feedback
            status={compilationFeedback?.status as FeedbackProps["status"]}
            title={compilationFeedback?.label}
            description={compilationFeedback?.description}
          />
        )}
        {isConnected && action === "execution" && (
          <Feedback
            {...executionFeedback}
            actions={
              executionFeedback.status === "success" ? (
                <span
                  className="font-youth text-accent font-medium text-sm"
                  role="button"
                  onClick={() => setIsModalOpen(true)}
                >
                  Show details
                </span>
              ) : undefined
            }
          />
        )}
        {isConnected &&
          action === "simulation" &&
          compilationResult.type === "compiled" &&
          vaultOwner &&
          vaultOwner !== "error" &&
          wallet && (
            <Simulator
              tx={{
                sim: compilationResult.result,
                value: "0x00",
                from: vaultOwner,
                to: vaultAddress,
              }}
              net={wallet.net}
            />
          )}
      </MainContent>
      <div className="flex items-center gap-3 self-stretch">
        {isConnected ? (
          <>
            {isOwner && (
              <Button
                isDisabled={isExecuteDisabled}
                onClick={handleExecuteClick}
              >
                {/* TODO: execute what */}
                Sign operation
              </Button>
            )}
            {!isOwner && vaultOwner && vaultOwner !== "error" && (
              <Button
                isDisabled={!isVaultOwnerInAccounts}
                onClick={handleAccountSwitch}
              >
                Switch to MSA owner account
              </Button>
            )}
            <Tooltip content={simulationWarning}>
              <Button
                isDisabled={isSimulateDisabled}
                variant="surface"
                onClick={() => setAction("simulation")}
              >
                Simulate
              </Button>
            </Tooltip>
          </>
        ) : (
          <Connect />
        )}
        <Deco variant="background" />
      </div>
      {wallet && executionResults && (
        <Modal
          title="Calldata executed:"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          {executionResults.map((res, i) => (
            <div className="flex flex-col items-start gap-2 self-stretch">
              <div className="flex flex-col gap-2 items-start p-2 self-stretch">
                <div className="text-sm font-medium text-font-variant">
                  Calldata #{i + 1}
                </div>
                <div className="flex items-start gap-2 self-stretch w-full">
                  <span className="font-youth pl-[5px] pr-1 text-sm font-medium text-font-variant">
                    {"->"}
                  </span>
                  <div className="flex flex-col flex-1 w-full overflow-hidden">
                    <span className="font-youth text-sm font-medium self-stretch w-full break-words line-clamp-2">
                      {res.calldata}
                    </span>
                    <a
                      className="text-sm font-medium text-font-variant"
                      href={`${wallet.net.explorer}/tx/${res.txHash}`}
                      target="_blank"
                    >
                      View in explorer
                    </a>
                    {/* TODO: add code here */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Modal>
      )}
    </Main>
  );
}
