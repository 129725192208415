import { Chips } from "../ui-kit/Chips";
import mass from "../assets/MassLogo.svg";
import { Deco } from "./Deco";
import { Nav } from "./Nav";
import { useHash, useWallet } from "../utils/utils";
import { WalletMenu } from "./WalletMenu";
import { NetworkMenu } from "./NetworkMenu";
import { GasTankMenu } from "./GasTankMenu";
import { Connect } from "./Connect";

export type HeaderProps = {
  currentTab: "code" | "analyze";
  onTabChange: (tab: "code" | "analyze") => void;
};

export function Header({ currentTab, onTabChange }: HeaderProps) {
  const [wallet] = useWallet();
  const [hash] = useHash();

  return (
    <header className="flex py-3 pl-4 pr-3 items-center self-stretch gap-6 z-50">
      <img src={mass} className="h-[13px]" />
      <Nav
        label="Code"
        isActive={currentTab === "code"}
        onClick={() => onTabChange("code")}
      />
      <Nav
        label="Analyze"
        isActive={currentTab === "analyze"}
        onClick={() => onTabChange("analyze")}
      />
      <Deco variant="surface" />
      <div className="flex pt-0.5 max-h-[32px] items-center gap-2.5">
        {wallet && wallet.connectedAccount && hash ? (
          <>
            <WalletMenu />
            <NetworkMenu />
            <GasTankMenu />
          </>
        ) : (
          <Connect isSmall />
        )}
        <Chips leading="?" className="!min-w-0" />
      </div>
    </header>
  );
}
